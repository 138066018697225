<template>
  <form v-bind="a.p(p.p)" :class="a.c(p.cl)">
    <slot></slot>
  </form>
</template>
<script setup>
import { onUpdated } from 'vue'
import { useAppeggio } from '../../useAppeggio.js'
const a = useAppeggio()
const p = defineProps({ p: { type: Object }, cl: { type: String }, modelValue: { type: [Object, String, Number, Boolean] } })
onUpdated(_ => {
  console.log('form', p.modelValue)
})
import { useWatchModel } from '../../composables/use-watch-model.js'
  const emit = defineEmits(['change'])
  const m = useWatchModel(p, emit)

</script>